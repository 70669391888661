

































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
@Component({})
export default class Name extends Vue {
  @Prop()
  private ifEdit: any;
  @Prop()
  private patient: any;
  @Prop()
  private data: any;
  @Watch("data", { immediate: true, deep: true })
  private dataChange() {
    this.drawBar();
  }
  private text: any = "";
  private tizhitu: any = {};
  private drawBar() {
    this.tizhitu = {
      color: ["#0a97a2"],
      tooltip: {
        show: true,
        trigger: "item",
      },
      grid: {
        left: "60",
        right: "2%",
        top: "20",
        bottom: "40",
      },
      xAxis: {
        type: "category",
        axisLabel: {
          color: "rgba(0,0,0,。8)",
        },
        axisLine: {
          lineStyle: {
            color: "rgba(0,0,0,0.6)",
          },
        },
        data: this.data["体质得分"].title,
      },
      yAxis: {
        name: "",
        type: "value",
        axisLine: {
          show: true,
        },
      },
      series: [
        {
          type: "bar",
          barWidth: "40",
          data: this.data["体质得分"].data,
          itemStyle: {
            color: "#6C99F6",
            barBorderRadius: [20, 20, 0, 0],
          },
        },
      ],
    };
  }
}
