var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"report-title"},[_vm._v(_vm._s(_vm.data["报告名称"]))]),_c('div',{staticClass:"info",staticStyle:{"font-size":"16px"}},[_c('div',[_vm._v("客户姓名："+_vm._s(_vm.patient["姓名"] || "/"))]),(_vm.patient['性别'])?_c('div',[_vm._v("性别："+_vm._s(_vm.patient["性别"] || "/"))]):_vm._e(),_c('div',[_vm._v(" 年龄："+_vm._s(_vm.patient["年龄"] || "/")),(_vm.patient['年龄'])?_c('span',[_vm._v("岁")]):_vm._e()]),_c('div',[_vm._v("采集日期："+_vm._s(_vm.data["检测时间"] || "/"))])]),_vm._m(0),_vm._m(1),_c('div',{staticClass:"result"},[_vm._v(_vm._s(_vm.data["脉象结论"]))]),_vm._m(2),_c('div',{staticClass:"echarts-wrap"},[(_vm.data['局部脉搏波形图'] && _vm.data['局部脉搏波形图'].length > 0)?_c('div',{staticClass:"echarts"},[_c('VueEcharts',{staticClass:"echarts-box",attrs:{"option":_vm.boxingtu}})],1):_vm._e(),(!_vm.data['局部脉搏波形图'] || _vm.data['局部脉搏波形图'].length == 0)?_c('Empty',{attrs:{"text":'暂无数据!'}}):_vm._e()],1),_vm._m(3),_c('div',{staticClass:"echarts-wrap"},[(
        _vm.data['经络柱状图'] &&
        _vm.data['经络柱状图'].data &&
        _vm.data['经络柱状图'].data.length > 0
      )?_c('div',{staticClass:"echarts"},[_c('VueEcharts',{staticClass:"echarts-box",attrs:{"option":_vm.zhuzhuangtu}})],1):_vm._e(),(
        (!_vm.data['经络柱状图'] && !_vm.data['经络柱状图'].data) ||
        _vm.data['经络柱状图'].data.length == 0
      )?_c('Empty',{attrs:{"text":'暂无数据!'}}):_vm._e()],1),_vm._m(4),_c('div',{staticClass:"xinlv"},[_c('div',[_c('div',[_vm._v("平均心率(bmp)")]),_c('span',[_vm._v(_vm._s(_vm.data["心率"]["data"][1]))])])]),_vm._m(5),_c('div',{staticClass:"jianyi"},[(_vm.ifEdit)?_c('el-input',{attrs:{"type":"textarea","autosize":{ minRows: 3 },"placeholder":"请输入"},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}}):_c('div',{staticClass:"jianyi-show"},[(_vm.text)?_c('pre',[_vm._v(_vm._s(_vm.text))]):_vm._e()])],1),_c('div',{staticClass:"tip"},[_vm._v(" 注：此报告仅为健康管理报告，不可作为诊断和治疗的依据。 ")])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"big-title"},[_c('img',{attrs:{"src":"/common/juxing2.png","alt":""}}),_c('span',[_vm._v("报告结果")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sub-title"},[_c('div'),_c('span',[_vm._v("脉象结论")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sub-title"},[_c('div'),_c('span',[_vm._v("局部脉搏波形图")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sub-title"},[_c('div'),_c('span',[_vm._v("经络柱状图")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sub-title"},[_c('div'),_c('span',[_vm._v("心率")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"big-title"},[_c('img',{attrs:{"src":"/common/juxing2.png","alt":""}}),_c('span',[_vm._v("医生备注")])])}]

export { render, staticRenderFns }