







































import { Component, Vue, Prop } from "vue-property-decorator";
import DsEditor from "@/components/ds-editor/ds-editor.vue";
import Empty from "@/components/empty.vue";
@Component({
  components: {
    DsEditor,
    Empty,
  },
})
export default class Name extends Vue {
  @Prop()
  private name: any;
  @Prop()
  private ifEdit: any;
  @Prop()
  private patient: any;
  @Prop()
  private data: any;
  private editorChange(val: any, item: any) {
    item.content = val;
  }
  private delFangan(item: any, i: any) {
    this.$confirm("您是否确定删除当前方案？", "删除", {
      customClass: "commonConfirm",
    }).then(() => {
      this.$emit("delFangan", i);
    });
  }
}
