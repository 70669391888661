






















































import { Component, Vue, Prop } from "vue-property-decorator";
@Component({})
export default class Name extends Vue {
  @Prop()
  private ifEdit: any;
  @Prop()
  private patient: any;
  @Prop()
  private data: any;
  private text: any = "";
}
