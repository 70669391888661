














































































































import { Component, Vue, Prop } from "vue-property-decorator";
import MainHeader from "@/components/main-header/main-header.vue";
import ReportFengmian from "../components/report-fengmian.vue";
import ReportShe from "../components/report-she.vue";
import ReportMian from "../components/report-mian.vue";
import ReportJingluo from "../components/report-jingluo.vue";
import ReportTizhi from "../components/report-tizhi.vue";
import ReportStatus from "../components/report-status.vue";
import ReportFangan from "../components/report-fangan.vue";
import { getFanganList } from "@/request/manage";
@Component({
  components: {
    MainHeader,
    ReportFengmian,
    ReportShe,
    ReportMian,
    ReportJingluo,
    ReportTizhi,
    ReportStatus,
    ReportFangan,
  },
})
export default class Name extends Vue {
  private patient: any = {};
  private data: any = [];
  private cpart: any = {};
  private ifEdit: any = false;
  private ifShowYangsheng: any = false;
  private ifShowGanyu: any = false;
  private save() {
    this.ifEdit = false;
  }
  private delFangan(i: any) {
    if (this.data[this.data.length - 1].fangans.length == 1) {
      this.data.splice(this.data.length - 1, 1);
    } else {
      this.data[this.data.length - 1].fangans.splice(i, 1);
    }
  }
  /**
   * @description 切换左边导航
   */
  private changePart(item: any) {
    const parant: any = document.getElementById("printReport");
    const children: any = document.getElementById(item.id);
    if (parant) {
      if (children) {
        const parentTop = parant.offsetTop;
        const childTop = children.offsetTop;
        parant.scrollTop = childTop - parentTop - 14;
      }
    }
    setTimeout(() => {
      this.cpart = item;
    }, 50);
  }
  private scrollEvent() {
    const parant = document.getElementById("printReport");
    if (parant) {
      const scrollTop = parant.scrollTop;
      const parentTop = parant.offsetTop + 14;
      this.data.forEach((ele: any, i: any) => {
        if (i < this.data.length - 1) {
          const cuEle = document.getElementById(ele.id);
          const nextEle = document.getElementById(this.data[i + 1]?.id);
          if (cuEle && nextEle) {
            const cuTop = cuEle.offsetTop - parentTop;
            const nextTop = nextEle.offsetTop - parentTop;
            if (scrollTop >= cuTop && scrollTop < nextTop) {
              this.cpart = ele;
            }
          }
        } else {
          const cuEle = document.getElementById(ele.id);
          if (cuEle) {
            const cuTop = cuEle.offsetTop - parentTop;
            if (scrollTop >= cuTop) {
              this.cpart = ele;
            }
          }
        }
      });
    }
  }
  private getData() {
    this.data.unshift({
      id: "fengmian",
      报告名称: "封面",
    });
    if (this.ifShowYangsheng) {
      this.data.push({
        id: "yangsheng",
        报告名称: "养生方案",
        fangans: [],
      });
    }
    if (this.ifShowGanyu) {
      this.data.push({
        id: "ganyu",
        报告名称: "干预方案",
        fangans: [],
      });
    }

    this.changePart(this.data[0]);
    if (this.ifShowYangsheng || this.ifShowGanyu) {
      let yangshengs: any = [];
      let ganyus: any = [];
      this.data.forEach((ele: any) => {
        if (this.ifShowYangsheng) {
          if (ele["养生方案"] && ele["养生方案"].length > 0) {
            ele["养生方案"].forEach((e: any) => {
              const params = {
                params: {
                  project_name: e.title,
                  ceping_name: ele["报告名称"],
                },
              };
              getFanganList(this, params).then((res: any) => {
                if (res.data.length > 0) {
                  res.data.forEach((d: any) => {
                    const obj = d;
                    d.origin = e;
                    d.origin["报告名称"] = ele["报告名称"];
                    yangshengs.fangans.push(obj);
                  });
                }
              });
            });
          }
        }
        if (this.ifShowGanyu) {
          if (ele["干预方案"] && ele["干预方案"].length > 0) {
            ele["干预方案"].forEach((e: any) => {
              const params = {
                params: {
                  project_name: e.title,
                  ceping_name: ele["报告名称"],
                },
              };
              getFanganList(this, params).then((res: any) => {
                if (res.data.length > 0) {
                  res.data.forEach((d: any) => {
                    const obj = d;
                    d.origin = e;
                    d.origin["报告名称"] = ele["报告名称"];
                    ganyus.push(obj);
                  });
                }
              });
            });
          }
        }
      });
      if (this.ifShowYangsheng && this.ifShowGanyu) {
        this.data[this.data.length - 2].fangans = yangshengs;
        this.data[this.data.length - 1].fangans = ganyus;
      } else {
        if (this.ifShowYangsheng) {
          this.data[this.data.length - 1].fangans = yangshengs;
        } else {
          this.data[this.data.length - 1].fangans = ganyus;
        }
      }
    }

    // if (fangan.length > 0) {
    //   this.data.push({
    //     id: "fangan",
    //     报告名称: "调养方案",
    //     fangans: fangan,
    //   });
    // }
  }
  private mounted() {
    if (this.$route.query.ifShowYangsheng) {
      this.ifShowYangsheng = this.$route.query.ifShowYangsheng;
    }
    if (this.$route.query.ifShowGanyu) {
      this.ifShowGanyu = this.$route.query.ifShowGanyu;
    }
    if (this.$route.query.patient) {
      this.patient = JSON.parse(this.$route.query.patient as any);
    }
    if (this.$route.query.data) {
      this.data = JSON.parse(this.$route.query.data as any);
      this.getData();
    }
  }
}
