var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"report-page"},[_c('div',{staticClass:"page-header",staticStyle:{"height":"0","overflow":"hidden"}},[_c('MainHeader')],1),_c('div',{staticClass:"top-btn"},[_c('div',{staticClass:"top-btn-left"}),_c('div',{staticClass:"top-btn-right"},[_c('div',{directives:[{name:"print",rawName:"v-print",value:('#printReport'),expression:"'#printReport'"},{name:"show",rawName:"v-show",value:(!_vm.ifEdit),expression:"!ifEdit"}]},[_vm._v("打印全部")]),(!_vm.ifEdit)?_c('div',{on:{"click":function($event){_vm.ifEdit = true}}},[_vm._v("编辑")]):_vm._e(),(_vm.ifEdit)?_c('div',{on:{"click":function($event){return _vm.save()}}},[_vm._v("切换打印模式")]):_vm._e(),_c('div',{on:{"click":function($event){return _vm.$router.back()}}},[_vm._v("关闭")])])]),_c('div',{staticClass:"main-wrap"},[_c('div',{staticClass:"main-wrap-content"},[_c('div',{staticClass:"left-tab"},_vm._l((_vm.data),function(item,index){return _c('div',{key:index},[_c('div',{class:{ active: _vm.cpart.id === item.id },on:{"click":function($event){return _vm.changePart(item)}}},[_vm._v(" "+_vm._s(item["报告名称"])+" ")])])}),0),_c('div',{staticClass:"printContent",attrs:{"id":"printReport"},on:{"scroll":_vm.scrollEvent}},[_c('div',{attrs:{"id":"pdfCentent"}},_vm._l((_vm.data),function(items,index){return _c('div',{key:index},[(
                (items['报告名称'] == '调养方案' &&
                  items.fangans &&
                  items.fangans.length > 0) ||
                items['报告名称'] != '调养方案'
              )?_c('div',{staticClass:"page",staticStyle:{"page-break-before":"always","box-sizing":"border-box","padding":"40px"},attrs:{"id":items.id}},[(items['报告名称'] == '封面')?_c('ReportFengmian'):(items['报告名称'] == '舌图数据采集报告')?_c('ReportShe',{attrs:{"patient":_vm.patient,"data":items,"ifEdit":_vm.ifEdit}}):(items['报告名称'] == '面图数据采集报告')?_c('ReportMian',{attrs:{"patient":_vm.patient,"data":items,"ifEdit":_vm.ifEdit}}):(items['报告名称'] == '经络数据采集报告')?_c('ReportJingluo',{attrs:{"patient":_vm.patient,"data":items,"ifEdit":_vm.ifEdit}}):(
                  items['报告名称'] == '老年人体质辨识' ||
                  items['报告名称'] == '中医体质辨识'
                )?_c('ReportTizhi',{attrs:{"patient":_vm.patient,"data":items,"ifEdit":_vm.ifEdit}}):(items['报告名称'] == '中医健康状态智能测评')?_c('ReportStatus',{attrs:{"patient":_vm.patient,"data":items,"ifEdit":_vm.ifEdit}}):(items['报告名称'] == '养生方案')?_c('ReportFangan',{attrs:{"patient":_vm.patient,"data":items,"ifEdit":_vm.ifEdit,"name":"养生方案"},on:{"delFangan":_vm.delFangan}}):(items['报告名称'] == '干预方案')?_c('ReportFangan',{attrs:{"patient":_vm.patient,"data":items,"ifEdit":_vm.ifEdit,"name":"干预方案"},on:{"delFangan":_vm.delFangan}}):_c('div',[_vm._v(_vm._s(items["报告名称"])+"报告缺失")])],1):_vm._e(),_c('div',{staticClass:"noprint",staticStyle:{"border":"1px dashed #ccc","margin":"30px 0"}})])}),0)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }